import React, { useEffect } from 'react';
import {
  WalletProvider,
  HippoWalletAdapter,
  AptosWalletAdapter,
  HippoExtensionWalletAdapter,
  MartianWalletAdapter,
  FewchaWalletAdapter,
  PontemWalletAdapter,
  SpikaWalletAdapter,
  RiseWalletAdapter,
  FletchWalletAdapter,
  BloctoWalletAdapter,
  WalletAdapterNetwork
} from '@manahippo/aptos-wallet-adapter';
import Game from "./Game"
import { AptosWalletName, useWallet } from "@manahippo/aptos-wallet-adapter"





const wallets = [
  new HippoWalletAdapter(),
  new MartianWalletAdapter(),
  new AptosWalletAdapter(),
  new FewchaWalletAdapter(),
  new HippoExtensionWalletAdapter(),
  new PontemWalletAdapter(),
  new SpikaWalletAdapter(),
  new RiseWalletAdapter(),
  new FletchWalletAdapter(),
  new BloctoWalletAdapter({network:WalletAdapterNetwork.Mainnet, bloctoAppId:'c1938457-aee7-41fb-89b4-520e6367686b' }),
];

const App: React.FC = () => {
  
  
  return (
    <WalletProvider
      wallets={wallets}
      autoConnect={false} /** allow auto wallet connection or not **/
      onError={(error: Error) => {
        console.log('Handle Error Message', error);
      }}>
      {<Game></Game>}
    </WalletProvider>
  );
};

export default App;