import React, { useCallback, useEffect, useState } from 'react';
import logo from './img/logo.png';
import './App.css';
import { AptosAccount, AptosAccountObject,AptosClient, BCS, CoinClient, HexString, MaybeHexString, TokenClient, TransactionBuilder, TxnBuilderTypes, Types } from 'aptos';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { AptosWalletName,BloctoWalletName,PontemWalletName,RiseWalletName, useWallet, MartianWalletName , NetworkInfo, WalletAdapterNetwork, AccountKeys, Wallet} from "@manahippo/aptos-wallet-adapter"
import { ListCardUnstaked } from './components/ListCardUnstaked';
import { ListCardStaked } from './components/ListCardStaked';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';



const aptosWeb3 = require('@martiandao/aptos-web3-bip44.js');

type StakedNFT = {
  staked_nft_names: [];
};


 function Game() {

  

  const firebaseConfig = {
    apiKey: "AIzaSyDssuAM-zxL9oHjSZuBs5FkQ-IQZwSXVKs",
    authDomain: "metaagestaking.firebaseapp.com",
    projectId: "metaagestaking",
    storageBucket: "metaagestaking.appspot.com",
    messagingSenderId: "235789105086",
    appId: "1:235789105086:web:7c2b7c373bc8d801ae2bef",
    measurementId: "G-83JR84KTDG"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  
  var buildUrl = "./webgl/Build/";
  
  const NODE_URL = "https://fullnode.mainnet.aptoslabs.com";
  const FAUCET_URL = "https://faucet.mainnet.aptoslabs.com";
  const client = new AptosClient(NODE_URL);
  const coinClient = new CoinClient(client); 
  const tokenClient = new TokenClient(client); 
  const walletClient = new aptosWeb3.WalletClient(NODE_URL, FAUCET_URL);

  const [currentTokens,SetCurrentTokens] = useState< string[]>([]);
  const [stakedTokens,SetStakedTokens] = useState< string[]>([]);
  const [unstakableTokens, setUnstakableTokens] = useState<{ key: string; value: string; }[]>([]);
  const [stakableTokens, setStakableTokens] = useState<{ key: string; value: string; }[]>([]);
  const [open, setOpen] = React.useState(false);
  const { connect, disconnect, connected,signAndSubmitTransaction,account } = useWallet();
  const [unstakedLoading, setUnstakedLoading] = useState(true);
  const [stakedLoading, setStakedLoading] = useState(true);
  const [progress, setProgress] = React.useState(0);

  const collection = "Apetos Apes";
  
  

 

 useEffect(() => {
  
    
  setOpen(false)
  
  
  //setIsConnected(true)
  
}, [connected]);
  
  
  async function StakeToken(tokenName:string) {
    
   
      
      const transaction = {
        arguments: ["0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57","0xb116e5baf9412b2e9229a419a4fd5be795a1abf9855af7d1e1b1786979e2c922"  ,collection,tokenName,0,1],
        function: '0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57::tokenstaking::stake_token_new',
        type: 'entry_function_payload',
        type_arguments: [],
      };
      
      const result = await  signAndSubmitTransaction(transaction)
      setTimeout(function() {
        getUnstakedTokens()
        GetStakedNFTs()
      }, 1000);
    

    
  }
  
  async function UnstakeToken(tokenName:string) {
    
    const transaction = {
      arguments: ["0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57","0xb116e5baf9412b2e9229a419a4fd5be795a1abf9855af7d1e1b1786979e2c922",collection,tokenName,0,1],
      function: '0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57::tokenstaking::unstake_token',
      type: 'entry_function_payload',
      type_arguments: ["0x40c2bd88c1313795ff0c6202aa58d8a6545ceaca1dafa5208c7586fb0f9d6b96::bananacoin::BananaToken"],
    };
    
 
      
      
      const result = await  signAndSubmitTransaction(transaction)

      
      setTimeout(function() {
        getUnstakedTokens()
        GetStakedNFTs()
      }, 1000);
  }
// Establish connection to the wallet
async function ConnectWallet() {

  setOpen(true)

}
const getUnstakedTokens = async () => {

 

  var tokens:{ key: string; value: string; }[] = []
  var tokenNames:string[] = []


  async function fetchGraphQL(operationsDoc: string, operationName: string, variables: { owner_address: any; offset: any; }) {
    const result = await fetch(
      "https://indexer.mainnet.aptoslabs.com/v1/graphql",
      {
        method: "POST",
        body: JSON.stringify({
          query: operationsDoc,
          variables: variables,
          operationName: operationName
        })
      }
    );
  
    return await result.json();
  }
  
  const operationsDoc = `
    query CurrentTokens($owner_address: String, $offset: Int) {
      current_token_ownerships(
        where: {owner_address: {_eq: $owner_address}, amount: {_gt: "0"}, table_type: {_eq: "0x3::token::TokenStore"}}
        order_by: {last_transaction_version: desc}
        offset: $offset
      ) {
        token_data_id_hash
        name
        collection_name
        property_version
        amount
      }
    }
  `;
  
  function fetchCurrentTokens(owner_address: any, offset: any) {
    return fetchGraphQL(
      operationsDoc,
      "CurrentTokens",
      {"owner_address": owner_address, "offset": offset}
    );
  }
  
  async function startFetchCurrentTokens(owner_address: any, offset: any) {
    const { errors, data } = await fetchCurrentTokens(owner_address, offset);
  
    if (errors) {
      // handle those errors like a pro
      console.error(errors);
    }
  
    // do something great with this precious data
    console.log(data);
    for(let i = 0; i < data.current_token_ownerships.length; i++)
  {
    
    if( data.current_token_ownerships[i].collection_name == "Apetos Apes"){
    
      const stakableToken = await tokenClient.getTokenData("0xb116e5baf9412b2e9229a419a4fd5be795a1abf9855af7d1e1b1786979e2c922",collection,data.current_token_ownerships[i].name)
      
      const response = await fetch("https://ipfs.topaz.so//ipfs/"+stakableToken.uri.split("/")[2]+"/"+stakableToken.uri.split("/")[3])
          
          
          const metadataJson = await response.json()
        
          tokenNames.push(stakableToken.name)
          tokens.push({key:   stakableToken.name    ,    value:   "https://ipfs.topaz.so//ipfs/"+metadataJson.image.split("/")[2]+"/"+metadataJson.image.split("/")[3]})
          
    }
   
  }
  setUnstakedLoading(false)
  setStakableTokens(tokens)
  SetCurrentTokens(tokenNames)


  }
  
  startFetchCurrentTokens(account?.address, 0);
  


};
async function GetStakedNFTs() {
  var tokenNames:string[] = []
  const tokenUris = []
  const tokens: React.SetStateAction<{ key: string; value: string; }[]> = []
  
  
  
  if(account?.address != null){
    const resource = await client.getAccountResource(account.address,"0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57::tokenstaking::StakedTokenInfo");
    console.log(resource)
      
      


      for (let i = 0; i < (resource.data as any).staked_nfts.length; i++) {

        
          
        
        const unstakableToken = await tokenClient.getTokenData("0xb116e5baf9412b2e9229a419a4fd5be795a1abf9855af7d1e1b1786979e2c922",collection,(resource.data as any).staked_nfts[i])
          
        
        
          const response = await fetch("https://ipfs.topaz.so//ipfs/"+unstakableToken.uri.split("/")[2]+"/"+unstakableToken.uri.split("/")[3])
          
          //console.log("https://ipfs.topaz.so//ipfs/"+unstakableToken.uri.split("/")[2]+"/"+unstakableToken.uri.split("/")[3])
          const metadataJson = await response.json()
          
          /*const metadataImage = await fetch("https://ipfs.topaz.so//ipfs/"+ metadataJson.image.split("/")[2]+"/"+metadataJson.image.split("/")[3])
          console.log(metadataImage)*/
          tokenNames.push(unstakableToken.name)
          tokens.push({key:unstakableToken.name,value:"https://ipfs.topaz.so//ipfs/"+metadataJson.image.split("/")[2]+"/"+metadataJson.image.split("/")[3]})

         
        

      }
      setStakedLoading(false)
      setUnstakableTokens(tokens)
      SetStakedTokens(tokenNames)
  }

}

async function StakeAll() {
    
  console.log(currentTokens.length)
     
   const transaction = {
     arguments: ["0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57","0xb116e5baf9412b2e9229a419a4fd5be795a1abf9855af7d1e1b1786979e2c922"  ,collection,currentTokens,0,currentTokens.length-1],
     function: '0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57::tokenstaking::stake_all',
     type: 'entry_function_payload',
     type_arguments: [],
   };
   
   const result = await  signAndSubmitTransaction(transaction)
   setTimeout(function() {
     setUnstakedLoading(true)
     setStakedLoading(true)
     getUnstakedTokens()
     GetStakedNFTs()
   }, 500);
 

 
}
async function UnstakeAll() {
   
 console.log(stakedTokens.length)
    
  const transaction = {
    arguments: ["0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57","0xb116e5baf9412b2e9229a419a4fd5be795a1abf9855af7d1e1b1786979e2c922"  ,collection,stakedTokens,0,stakedTokens.length-1],
    function: '0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57::tokenstaking::unstake_all',
    type: 'entry_function_payload',
    type_arguments: ["0x40c2bd88c1313795ff0c6202aa58d8a6545ceaca1dafa5208c7586fb0f9d6b96::bananacoin::BananaToken"],
  };
  
  const result = await  signAndSubmitTransaction(transaction)
  setTimeout(function() {
    setUnstakedLoading(true)
    setStakedLoading(true)
    getUnstakedTokens()
    GetStakedNFTs()
  }, 500);



}
async function ClaimAll() {
   
 console.log(stakedTokens.length)
    
  const transaction = {
    arguments: [collection,stakedTokens,"0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57",stakedTokens.length-1],
    function: '0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57::tokenstaking::claim_all',
    type: 'entry_function_payload',
    type_arguments: ["0x40c2bd88c1313795ff0c6202aa58d8a6545ceaca1dafa5208c7586fb0f9d6b96::bananacoin::BananaToken"],
  };
  
  const result = await  signAndSubmitTransaction(transaction)
  



}
async function StartStaking() {
  const transaction = {
    type: "entry_function_payload",
    function: "0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57::tokenstaking::creator_start_staking",
    type_arguments: [],
    arguments: [collection],
  };
  const result = await  signAndSubmitTransaction(transaction)
}
async function DepositStakingReward() {
  const transaction = {
    arguments: [collection,100000000 * 100000],
    function: "0xc13dea8848fe30c03178700b6fafa6f0b3241217a87e340e7804cc4d7ae5ba57::tokenstaking::deposit_staking_rewards",   
    type: "entry_function_payload",
    type_arguments: ["0x40c2bd88c1313795ff0c6202aa58d8a6545ceaca1dafa5208c7586fb0f9d6b96::bananacoin::BananaToken"],
    
  };
  const result = await  signAndSubmitTransaction(transaction)
}
async function MintCoin() {
    
  
     
  const transaction = {
    arguments: [10000000000000],
    function: '0x40c2bd88c1313795ff0c6202aa58d8a6545ceaca1dafa5208c7586fb0f9d6b96::bananacoin::mint',
    type: 'entry_function_payload',
    type_arguments: [],
  };
  
  const result = await  signAndSubmitTransaction(transaction)
  



}

useEffect(() => {
  
 
  
 
  if (account?.address) {
    getUnstakedTokens();
    GetStakedNFTs()
  }
}, [account]);




  return (
    <div style={{textAlign:"center",backgroundColor:"black",backgroundSize:"cover",width:"100vw",height:"100vh",justifyContent:"center"}}>
     
      
      
     
      

      <div style={{display:"flex",flexDirection:"row",width:"100%",backgroundColor:"black"}}>
                      
                      <div style={{display:"flex",flexDirection:"row",textAlign:"center",width:"100vw",height:"auto",backgroundColor:"black",marginTop:"2vw"}}>

                      <Avatar src={logo} style={{width:"6vw",height:"auto",marginLeft:"2vw"}}></Avatar>
                      <h1 style={{color:"white",marginLeft:"2vw",fontFamily:"VT323",fontSize:"2vw"}}>Apetos Apes</h1>
                      
                      </div>
                      <div style={{width:"100vw",height:"auto",backgroundColor:"black",justifyContent:"end",textAlign:"end"}}>

                      <Button style={{fontFamily:"VT323",marginTop:"4vw",marginRight:"5vw",fontSize:"1vw",backgroundColor:"rgb(35, 56, 77,1)"}} variant="contained" onClick={ConnectWallet} disabled={connected}>Connect Wallet</Button>
                      
                      </div>
                </div>

                <div style={{display:"flex",flexDirection:"column",width:"100vw",height:"auto",alignItems:"center",backgroundColor:"black"}}>



                


                <div style={{display:"flex",flexDirection:"row",width:"65vw",height:"auto",marginTop:"2vw"}}>


                <Button variant='contained' style={{fontFamily:"VT323",maxHeight:"7vh",marginRight:"2vw",fontSize:"1vw",backgroundColor:"rgb(35, 56, 77,1)",marginTop:"1vw"}} onClick={StakeAll} >Stake All</Button>
                <div  style={{display:"flex",flexDirection:"column",width:"50%",height:"auto",marginTop:"1vw"}}>

                <div  style={{display:"flex",flexDirection:"column",width:"100%",height:"auto",backgroundColor:"rgb(35, 56, 77,1)",borderRadius:"2%",textAlign:"center"}}>
                <h1 style={{color:"white",fontFamily:"VT323",fontSize:"2vw",fontWeight:"bold"}}>Unstaked NFTs</h1>
                </div>



                <div style={{display:"flex",flexDirection:"row",width:"100%",minHeight:"50vh",marginTop:"1vw",backgroundColor:"rgb(35, 56, 77,1)",borderRadius:"2%"}}>
                {!unstakedLoading && (
                    <Grid direction={"row"} container spacing={ 0 } columns={2} >

                  
                    {stakableTokens?.map((token,i) =>(
                      <Grid  key={i.toString()}>
                          <ListCardUnstaked
                          key={i.toString()}
                          tokenName={token.key}
                          uri={token.value}
                          onClick={() => StakeToken(token.key)}
                          />
                      </Grid>
                          
                        
                        ))}
                    </Grid>



                )}


                {unstakedLoading && connected && (

                <h1 style={{color:"white",fontFamily:"VT323",fontSize:"2vw",marginTop:"5vw"}}>Loading...</h1>


                )}

                </div>


                </div>

                <div  style={{display:"flex",flexDirection:"column",width:"50%",height:"auto",marginTop:"1vw",marginLeft:"2vw"}}>

                <div  style={{display:"flex",flexDirection:"column",width:"100%",height:"auto",backgroundColor:"rgb(35, 56, 77,1)",borderRadius:"2%",textAlign:"center"}}>
                <h1 style={{color:"white",fontFamily:"VT323",fontSize:"2vw",fontWeight:"bold"}}>Staked NFTs</h1>
                </div>



                <div style={{display:"flex",flexDirection:"row",width:"100%",minHeight:"50vh",marginTop:"1vw",backgroundColor:"rgb(35, 56, 77,1)",borderRadius:"2%"}}>
                {!stakedLoading && (
                    <Grid direction={"row"} container spacing={ 0 } columns={2} >

                  
                    {unstakableTokens?.map((token,i) =>(
                      <Grid  key={i.toString()}>
                          <ListCardStaked
                          key={i.toString()}
                          tokenName={token.key}
                          uri={token.value}
                          onClick={() => UnstakeToken(token.key)}
                          />
                      </Grid>
                          
                        
                        ))}
                    </Grid>



                )}


                {stakedLoading && connected && (

                <h1 style={{color:"white",fontFamily:"VT323",fontSize:"2vw",marginTop:"5vw"}}>Loading...</h1>


                )}

                </div>


                </div>

                <div style={{display:"flex",flexDirection:"column"}}>
                <Button variant='contained' style={{fontFamily:"VT323",fontWeight:"bold",fontSize:"1vw",backgroundColor:"rgb(35, 56, 77,1)",marginTop:"1vw",maxHeight:"7vh",marginLeft:"2vw"}} onClick={UnstakeAll} >Unstake All</Button>
                <Button variant='contained' style={{fontFamily:"VT323",fontWeight:"bold",fontSize:"1vw",backgroundColor:"rgb(35, 56, 77,1)",marginTop:"1vw",maxHeight:"7vh",marginLeft:"2vw"}} onClick={ClaimAll} >Claim Rewards</Button>
                </div>

                  
                </div>






</div>
      <Modal
                  open={open}
                  onClose={ConnectWallet}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{width:"20vw",color:"white",borderRadius:"10%",justifyContent:"center",textAlign:"center",backgroundColor:"rgba(33, 66, 53)",alignContent:"center",position:"absolute",top:"40%",left:"40%",alignItems:"center"}}>
                   <Button onClick={() =>connect(MartianWalletName)} variant="contained" sx={{width:"15vw",marginTop:"1vw",fontFamily:"VT323",fontSize:"1vw",backgroundColor:"rgb(35, 56, 77,1)"}} >Martian</Button>
                   <Button onClick={() =>connect(AptosWalletName)} variant="contained" sx={{width:"15vw",marginTop:"1vw",fontFamily:"VT323",fontSize:"1vw",backgroundColor:"rgb(35, 56, 77,1)"}} >Petra</Button>
                   <Button onClick={() =>connect(RiseWalletName)} variant="contained" sx={{width:"15vw",marginTop:"1vw",fontFamily:"VT323",fontSize:"1vw",marginBottom:"1vw",backgroundColor:"rgb(35, 56, 77,1)"}} >Rise</Button>
                   <Button onClick={() =>connect(PontemWalletName)} variant="contained" sx={{width:"15vw",marginTop:"0vw",fontFamily:"VT323",fontSize:"1vw",marginBottom:"1vw",backgroundColor:"rgb(35, 56, 77,1)"}} >Pontem</Button>
                   <Button onClick={() =>connect(BloctoWalletName)} variant="contained" sx={{width:"15vw",marginTop:"0vw",fontFamily:"VT323",fontSize:"1vw",marginBottom:"1vw",backgroundColor:"rgb(35, 56, 77,1)"}} >Blocto</Button>
                  </Box>
                </Modal>
      
      
    
        
      
    </div>
    
  );
}

export default Game;
