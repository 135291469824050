

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React from "react";
import { Token } from "../types";


type CardProps = {  key: string; tokenName:String;uri:string; onClick: any  };
export function ListCardStaked({ tokenName: tokenName,uri:uri, onClick  }: CardProps) {
  
  return (
    <div style={{display:"flex",flexDirection:"column",textAlign:"center",justifyContent:"start",alignContent:"center"}}>
      <img style={{width:"80%",alignSelf:"center",borderRadius:"5%"}} src={uri} alt="picture" />
      <div>
        
        
        
          <button
           
            onClick={onClick}
            style={{ width:"80%",borderRadius:"5%",color:"white",fontSize:"0.6vw",fontFamily:"VT323",fontWeight:"bold",backgroundColor:"#94b0da",border:"none"}}
          >
            {"Unstake"}
          </button>
        
      </div>
    </div>
  );
}
