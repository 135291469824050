import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import walletProvider from "./Game"

declare global {
  interface Window { martian: any; }
}
declare global {
  interface Window { aptos: any; }
}
declare global {
  interface Window { unityInstance: any; }
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
window.addEventListener('load', () => {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
